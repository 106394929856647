import React from "react"
import Sticky from "react-sticky-el"
import { graphql } from "gatsby"
import SEO from "../components/SEO/index"
import NewsPageSection from "../components/Sections/NewsPageSection"
import NewsHero from "../components/Sections/NewsHero"
import StyledBackgroundNews from "../components/BackgroundNews"
import NavBar from "../components/navbar"

const NewsPage = ({ data }) => {
  return (
    <>
      <SEO
        title="UZMBC"
        description="Multi Business Company - Новости компании"
      />
      <Sticky className="sticky-selector" topOffset={150}>
        <div>
          <NavBar />
        </div>
      </Sticky>
      <StyledBackgroundNews>
        <NewsHero />
      </StyledBackgroundNews>
      <NewsPageSection news={data.news} data={data.section} />
    </>
  )
}

export const NewsPageQuery = graphql`
  query {
      news: allSanityNews {
        edges {
          node {
            title
            image {
              asset {
                gatsbyImageData
              }
            }
            block : _rawBlock(resolveReferences: {maxDepth: 10})
          }
        }
      }
    section: allSectionYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
        }
      }
    }
  }
`

export default NewsPage
