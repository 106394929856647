import React from "react"
import {PortableText} from "@portabletext/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Section, SectionTitle } from "../CompoundComponents/Section"
import { Box } from "../CompoundComponents/Box"
import {ShoppingConsumer} from "../../layouts";
import {Card, CardContent, CardContentTitle} from "../CompoundComponents/Card";

const NewsPageSection = ({ data, news }) => {
    console.log(news);
  return (
    <Section>
      <Columns>
          {
              news.edges.map((item, i) => (
                  <Column is4>

                      <ShoppingConsumer>
                          {({ isModal, handleClick }) => (
                              <>
                                  <div onClick={() => handleClick(item.node.title)}>
                                      <Card>
                                          <CardContent>
                                              <CardContentTitle>
                                                  {item.node.title}
                                              </CardContentTitle>
                                          </CardContent>
                                      </Card>
                                  </div>
                                  <div
                                      className={`modal ${isModal === item.node.title && "is-active"}`}
                                  >
                                      <div className="modal-background"></div>
                                      <div className="modal-content">
                                          <Box>
                                              <GatsbyImage
                                                  image={getImage(item.node.image.asset.gatsbyImageData)}
                                              />
                                              <div className="content">
                                                  <PortableText
                                                      value={item.node.block}
                                                  />
                                              </div>
                                          </Box>
                                      </div>
                                      <button
                                          onClick={handleClick}
                                          className="modal-close is-large"
                                          aria-label="close"
                                      ></button>
                                  </div>
                              </>
                          )}
                      </ShoppingConsumer>
                  </Column>
              ))
          }
      </Columns>
    </Section>
  )
}

export default NewsPageSection
